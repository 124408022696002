<template>
  <main class="grid-container grow auto-rows-min mt-5">
    <Banner />
    <!--    <Actions-->
    <!--      title="Акции и предложения"-->
    <!--      class="mt-10 lg:mt-18"-->
    <!--    />-->
    <Catalog
      v-if="category.length > 0"
      title="Выберите категорию"
      class="mt-10 lg:mt-18"
      :link="{ to: '/categories', text: 'Все категории еды' }"
      :items="category"
    />
    <!-- <RestaurantProducts
      title="Каталог блюд"
      class="mt-11 lg:mt-24"
      :children="productList"
      :restaurant-title-is-show="true"
    /> -->
    <Catalog
      title="Кухня"
      class="mt-11 lg:mt-24"
      :link="{ to: '/kuhni', text: 'Все кухни мира' }"
      :items="kuhni"
    />
    <Catalog
      title="Блюда"
      class="mt-11 lg:mt-24"
      :link="{ to: '/kuhni', text: 'Все блюда' }"
      :items="bluda"
    />
    <Restaurant
      class="mt-11 lg:mt-24"
      :restaurants
      title="Заведения в&nbspЮжно‑Сахалинске"
      :link="{path:'/restaurants',title:'Все заведения в Южно-Сахалинске'}"
    />

    <MainEndText class="mt-11 lg:mt-24 max-lg:hidden" />
    <MainEndBlock
      class="mt-14 lg:mt-18"
      color="gray"
    />
  </main>
</template>

<script setup lang="ts">
import { useProduct } from '~/composables/product/useProduct';
import { useCategory } from '~/composables/category/useCategory';
import { useRestaurant } from '~/composables/restaurant/useRestaurant';
import { useStaticPage } from '~/composables/static-page/useStaticPage';
import { useProductPopup } from '~/composables/popups/useProductPopup';
const { openPopup } = useProductPopup();
useHead({
  bodyAttrs: { class: 'bg-white' }
});

const { productList, getProductBySlug } = useProduct();
const { categoryList: categories } = useCategory();
const { restaurantList: restaurants } = useRestaurant();

const category = computed(() => {
  return categories.value.filter((t) => t.type.description === 'Категория');
});
const kuhni = computed(() => {
  return categories.value.filter((t) => t.type.description === 'Кухня');
});
const bluda = computed(() => {
  return categories.value.filter((t) => t.type.description === 'Блюдо');
});

const $route = useRoute();
const { setSEOTitles } = seoHandlers();
const { getStaticPageByUrl } = useStaticPage();
const { data, error } = await useAsyncData(() => getStaticPageByUrl(`${$route.path}`));

if (data.value) {
  setSEOTitles({
    seoTitle: data.value?.seo_title || '',
    seoDescription: data.value?.seo_description || '',
    seoKeywords: data.value?.seo_keys || ''
  });
}

async function getAdditionalIngredients () {
  if ($route.query.product_slug) {
    // @ts-ignore
    const res = await getProductBySlug($route.query.product_slug)
      .catch((e) => {
        console.log(e._data.message);
        return null;
      });
    if (res) {
      openPopup({
        currentProduct: res
      });
    }
  }
  return;
}

onMounted(() => {
  getAdditionalIngredients();
});

</script>
