import type { ServerResponseCommonType } from '~/types/commons';
import type { BannerType } from './types/BannerTypes';

type TransportBannerType = {
    getBanners: () => Promise<BannerType[] | null>
}

export function useBanners ():TransportBannerType {
  const { $api } = useNuxtApp();

  const getBanners = async () => {
    const res = await $api<ServerResponseCommonType<BannerType[] | null>>('/banner/getList');
    if (res && res.data) {
      return res.data;
    }
    return null;
  };

  return {
    getBanners
  };
}
