<template>
  <source
    :media="media"
    :srcset="image.srcset"
    :sizes="image.sizes"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
	src: string,
	media?: string,
	sizes?: string,
}>();
const { getSizes } = useImage();
const image = computed(() => getSizes(props.src, { sizes: props.sizes }));
</script>
